.main-header {
  padding: 60px 20px 28px 20px;
  text-align: center;
  img:first-child {
    text-align: center;
    margin-bottom: 16px;
  }
}

@include media-breakpoint-down(sm) {
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 54px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    .container {
      padding: 0;
      margin: 0;
      top: 0;
      position: absolute;
      img:first-child {
        height: 34px;
        margin: 9px 0 0 12px;
        display: inline-block;
      }
      img:last-child {
        display: none;
      }

    }

  }
}

