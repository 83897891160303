.management {
  margin-top: 60px;
  border-bottom: $main-color 1px dotted;


  h2 {
    margin-bottom: 10px;
    text-align:  center;
  }

  h5 {
    text-align:  center;
    margin-bottom: 40px;
  }

  .management-message {
    margin-top: 20px;
    margin-bottom: 20px;
    background: $light-color;
    padding: 40px 15%;
    text-align: center;
  }

  .management-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .management-item {
    display: flex;
    flex-basis: 32%;
    margin-bottom: 24px;
    flex-direction: column;
    background: $light-color;

    h6 {
      background-size: cover;
      text-align: center;
      padding: 80px 30px;
      font-family: 'Lato', sans-serif;
      vertical-align: middle;
      display: inline-block;
      font-size: 1.4em;
      font-weight: bold;
      color: #fff;
      text-shadow: 0 0 6px rgba(0,0,0,.5);
    }

    p {
      padding: 20px;
    }
  }

  .mi-1 h6 {
    background: url(../img/management/fotolia_99428698.jpg) no-repeat center center;
  }
  .mi-2 h6 {
    background: url(../img/management/fotolia_109826926.jpg) no-repeat center center;
  }
  .mi-3 h6 {
    background: url(../img/management/fotolia_111308619.jpg) no-repeat center center;
  }
  .mi-4 h6 {
    background: url(../img/management/fotolia_90070024.jpg) no-repeat center center;
  }
  .mi-5 h6 {
    background: url(../img/management/fotolia_103611165.jpg) no-repeat center center;
  }
  .mi-6 h6 {
    background: url(../img/management/fotolia_83348585.jpg) no-repeat center center;
  }
}

@include media-breakpoint-down(sm) {
  .management {
    .management-wrap {
      .management-item {
        flex-basis: 100%;
      }
    }
  }

}
