.credentials {
  margin-top: 60px;
  border-bottom: $main-color 1px dotted;


  h2 {
    margin-bottom: 10px;
    text-align:  center;
  }

  h5 {
    text-align:  center;
    margin-bottom: 40px;
  }

  .credentials-hero {
    background: url(../img/fotolia_90931660.jpg) no-repeat center center;
    background-size: cover;
    min-height: 400px;
    margin-bottom: 60px;
    color: #fff;
    padding: 0;

    p {
      padding: 34px 15%;
      font-size: 1.24em;
      line-height: 1.3;
      letter-spacing: 0.05em;
      text-align: center;
      float: right;
      font-weight: 100;
      background: rgba(0,0,0,.8);
      margin: 140px 0 0;
    }
  }
}