.legal {
  margin-top: 60px;
  padding-bottom: 60px;
  min-height: 70vh;

  h2 {
    margin-bottom: 50px;
    text-align:  center;
  }

  .legal-image {
    width: 100%;
    margin-bottom: 30px;
  }

}