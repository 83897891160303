.user-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;

  &:after {
    content: '';
    display: inline-block;
    background: url(../icons/user.svg) no-repeat;
    height: 18px;
    width: 18px;
    margin: -4px 2px 0 -2px;
  }
}


.phone-icon {
  display: inline-block;
  vertical-align: middle;

  &:after {
    content: '';
    display: inline-block;
    background: url(../icons/phone.svg) no-repeat;
    height: 20px;
    width: 20px;
    margin: 0 4px 0 0;
  }
}

