footer {
  background: $secondary-color;
  margin-top: 0;
  padding: 0;
  color: #fff;
  font-size: 0.7em;

  .logo-footer {
    text-align: center;
    img {
      height: 140px;
    }
  }

  .contact-item {
    p {
      font-size: 1.1em;
      .phone {
        font-size: 1.8em;
        strong {
          font-size: 0.7em;
          display: block;
          letter-spacing: 0.05em;
        }
      }
      a {
        color: $light-color;
        font-size: 1.2em;
      }
    }
    h3 {
      margin-bottom: 24px;
    }
  }

  .footer-legal {
    padding-top: 14px;
    font-weight: lighter;

    p {
      display: inline-block;
      margin-right: 10px;
    }

    .legal {
      margin: 0;
      padding: 0;
      display: inline;
      list-style: none;
      li {
        display: inline-block;
        border-right: 2px solid rgba(255,255,255,.2);
        margin-right: 10px;
        padding-right: 10px;
        line-height: 1;
        &:last-child {
          border-right: 0;
        }
        a {
          color: $main-color;
        }
      }
    }
  }

  .prefooter {
    background: $prefooter;
    padding: 40px;
  }
}

@include media-breakpoint-down(sm) {
  .footer-legal {
    padding-bottom: 20px;
  }

  footer {
    .logo-footer img {
      height: 110px;
      margin: 20px 0;
    }

    .prefooter {
      padding: 0 10px;
    }
    .contact-item {
      text-align: center;
      h3 {
        font-size: 2em;
        margin-bottom: 14px;
      }
      h5 {
        font-size: 1.6em;
      }

      p a {
        font-size: 1.1em;
      }
    }
  }

}
