.method {
  margin-top: 80px;
  background: #eae2d5;

  .method-flex {
    display: flex;
    flex-direction: row;
    .method-image {
      display: flex;
      flex-basis: 28%;
      background: url(../img/method2.jpg) no-repeat center center;
      background-size: cover;
      padding: 20px;
      h4 {
        color: #fff;
        font-size: 1.7em;
        font-weight: 400;
        line-height: 1.2;
        font-family: 'Open Sans', sans-serif;
        text-align: right;
        text-shadow: 0 0 5px rgba(0,0,0,.5);
      }
    }
    .method-items {
      background: #ddd5c7;
      padding: 30px 20px 20px;
      ul {
        margin: 0;
        padding: 0 0 0 20px;
        list-style: none;
        li {
          $height: 124px;

          vertical-align: top;
          display: inline-block;
          width: 48%;
          height: $height;
          margin-bottom: 18px;
          margin-right: 4px;
          padding: 8px 80px 8px 14px;
          position: relative;
          z-index: 1;
          cursor: pointer;

          &:before {
            content: '';
            width: 1%;
            height: $height;
            top: 0;
            left: 0;
            display: block;
            background: #b3a58f;
            position: absolute;
            z-index: -1;
            transition: width 300ms ease-in-out;
          }

          &:hover:before {
            width: 100%;

          }

          line-height: 1.3;
          font-size: .9em;
          h5 {
            font-weight: bold;
            font-size: 1.4em;
            line-height: 1;
            margin: 0 0 16px;
          }
        }
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .method {
    .method-flex {
      flex-direction: column;
      .method-items {
        padding: 20px 0;
        background: transparent;
        ul {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}
