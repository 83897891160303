.software{
  margin-top: 60px;
  border-bottom: $main-color 1px dotted;

  h2 {
    margin-bottom: 10px;
    text-align:  center;
  }

  h5 {
    text-align:  center;
    max-width: 70%;
    margin: 0 auto 40px;
  }

}