.main-menu {
  background: $secondary-color;
  border-radius: 0;
  padding: 2px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, .15);

  &#menu-collapse {
    margin-top: 54px;
  }
  .menu-items {
    font-size: 0.9em;
    margin-top: 6px;
    li {
      display: inline-block;
      margin: 0 0 0 20px;
      a{
        margin: 0;
        padding: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .menu-items {
    }
    .nav-item {
      width:100%;
      display: block;
      margin-left: 0 !important;
      text-align: center;
      padding: 0.6rem;
      float: none;
      &:hover {
        background: #444;
      }
    }

    .phone-link {
      display: block;
      width: 100%;
      padding: 30px 0 14px;
      text-align: center;
    }
  }

  .user-access {
    .user-icon {
      opacity: 0.6;
      transition: opacity 200ms ease-in-out;
    }
    &:hover .user-icon {
      opacity: 1;
    }
  }

  a.phone-link
  {
    margin-top: 7px;
    color: #eee !important;
  }
}

@include media-breakpoint-up(md) {
  .main-menu {
    padding-bottom: 8px;
    a.phone-link {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .main-menu a.phone-link {
    display: inline-block;
  }
}

