.business-areas {
  text-align: center;
  padding: 60px 0 40px;

  h2 {
    color: $secondary-color;
    font-size: 1.6em;
    margin-bottom: 34px;
    padding: 8px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      $item-size: 160px;

      vertical-align: top;
      background: lighten($main-color, 50%);
      width: $item-size;
      height: $item-size;
      display: inline-block;
      //border-radius: 50%;
      margin-right: 10px;
      padding: 30px 22px 22px;
      position: relative;
      transition: all 240ms ease-in-out;

      img {
        margin-bottom: 8px;
      }
      a {
        color: $main-color;
        font-weight: bold;
        font-size: .9em;
        display: block;
        line-height: 1.2;
        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        transform: scale(1.2);
        cursor: pointer;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        z-index: 100;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .business-areas {
    ul {
      li {
        width: 140px;
        height: 140px;
        margin-bottom: 10px;
        //&:last-child:nth-child(2n+1) {
        //  margin-top: -16px;
        //}
      }
    }
  }
}