.marketing {

  .container {
    $padding: 40px;
    $border: 2px solid $main-color;

    margin: 24px auto;
    padding-top: 10px;
    padding-bottom: $padding;
    border-top: $border;
    border-bottom: $border;
    text-align: center;
  }

  h1 {
    i {
      font-size: 2em;
      line-height: 1;
      letter-spacing: -0.1em;
    }
  }
}

@include media-breakpoint-down(sm) {
  .marketing {
    h1 {
      font-size: 1.4rem;
    }

    .container {
      padding-bottom: 20px;
    }
  }

}