@import '../../components/bootstrap/scss/bootstrap';


body {
  font-family: 'Lato', sans-serif;
  position: relative;
}


// Helpers

@import 'helpers/variables';

// Elements

@import 'elements/typography';
@import 'elements/buttons';
@import 'elements/icons';
@import 'elements/collapse';

// Modules

// Components

@import 'components/main-menu';
@import 'components/header';
@import 'components/hero';
@import 'components/columns';
@import 'components/dossier';
@import 'components/business-areas';
@import 'components/marketing';
@import 'components/method';
@import 'components/active-types';
@import 'components/management';
@import 'components/credentials';
@import 'components/map';
@import 'components/software';
@import 'components/legal';

@import 'components/footer';

@import 'components/modal/contact';


// Responsive

@import "responsive/xs";
