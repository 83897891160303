.text-columns {
  .container {
    background: #fff;
    margin-top: -120px;

    font-size: 0.98em;
    line-height: 1.6;
    box-shadow: 0 -8px 10px 0 rgba(0, 0, 0, .15);

    .column-item {
      padding: 30px 0;
      $padding-lateral: 40px;
      &:first-child {
        padding-right: $padding-lateral/4;
        padding-left: $padding-lateral;
      }
      &:last-child {
        padding-right: $padding-lateral;
        padding-left: $padding-lateral/4;
      }
      letter-spacing: 0.02em;
    }

    p {
      i {
        font-weight: 600;
      }
    }

  }
}

@include media-breakpoint-down(sm) {
  .text-columns {
    .container {
      padding-top: 40px;
      margin-top: 0;
      .column-item {
        padding: 0 20px !important;
      }
    }
  }

  .gest-img {
    text-align: center;
    margin-bottom: 40px;
  }
}