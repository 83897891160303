.map {

  margin-top: 60px;
  border-bottom: $main-color 1px dotted;


  h2 {
    margin-bottom: 10px;
    text-align:  center;
  }

  h5 {
    text-align:  center;
    max-width: 70%;
    margin: 0 auto 40px;
  }

  .map-content {
    text-align: center;
    margin-bottom: 60px;
  }
}

@include media-breakpoint-down(sm) {
  .map-content {
    img {
      width: 100%;
    }
  }
}