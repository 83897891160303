.dossier {
  margin-top: 80px;
  .dossier-image {
    overflow: hidden;
    $dossier-image-height: 420px;
    height: $dossier-image-height;
    background: url(../img/fotolia_88387544.jpg) no-repeat center center;
    background-size: cover;
  }
  .dossier-text {
    padding-right: 60px;
    position: relative;
    text-align: center;
    p {
      text-align: left;
    }
    img {
      margin-bottom: 8px;
    }
  }
  .dossier-download {
    text-align: center;
    .btn {
      display: inline-block;
      margin-bottom: 8px;
      font-size: .86em;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dossier {
    margin-top: 0;
    .dossier-text {
      padding: 0 20px;
      img {
        margin-left: -21px;
      }
    }
    .btn-primary-outline {
      width: 100%;
    }
  }
}