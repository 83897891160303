.hero-image {
  height: 500px;
  background: url(../img/hero2.jpg) no-repeat bottom right fixed;
  background-size: cover;
}

@include media-breakpoint-down(sm) {
  .hero-image {
    height: 220px;
    background-position: center right;
  }

}