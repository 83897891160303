.contact-modal {
  .modal-title {
    margin-bottom: 20px;
  }
  textarea {
    resize: none;

  }

  .btn-send {
    width: 100%;
    background: $secondary-color;
    color: white;
    transition: background 100ms ease;
    &:hover {
      background: lighten($secondary-color, 10%);
    }
  }
}