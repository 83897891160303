.active-types {

  margin-top: 60px;
  border-bottom: $main-color 1px dotted;


  h2 {
    margin-bottom: 10px;
    text-align:  center;
  }

  h5 {
    text-align:  center;
    margin-bottom: 40px;
  }

  .active-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .active-item {
    display: flex;
    flex-direction: column;
    flex-basis: 44%;
    margin: 20px 20px 40px;


    img {
      width: 100%;
    }
    h4 {
      margin: 10px 0 6px;
      font-weight: bold;
      color: $main-color
    }
    p {
      margin-top: 10px;
      width: 80%;
      font-size: .86em;
    }
  }
}

@include media-breakpoint-down(sm) {
  .active-types {
    .active-item {
      flex-basis: 100%;
      margin: 0 0 40px 0;
    }
  }
}